<template>
    <!-- <div class="saleteam-area">
        <div class="teams" v-for="(follow,index) in follows" :key="index">
            <div class="delete-member blue-circle" v-on:click="deleteEmployee(follow.id)">
                <img class="img-fluid" src="../../../assets/images/icons/Delete.svg" alt="">
            </div>
            <div class="team-img">
                <img class="img-fluid" src="../../../assets/images/profile/profile.svg" alt="" />
            </div>
            <div class="team-title">
                <h3>{{follow.user.name}}</h3>
                <p><span v-if="follow.role == 1">Employee</span><span v-if="follow.role == 2">Manager</span></p>
                <p v-if="follow.profession">{{follow.profession.title}}</p>
                <div class="member-status" :class="follow.status == 1 ? 'text-success':'text-danger'">
                    <span v-if="follow.status == 1">Active</span>
                    <span v-if="follow.status == 0">InActive</span>
                </div>
            </div>
        </div>
        <div class="teams w-100" v-if="follows.length == 0">
            <div class="add-new d-block mt-5">
                <img src="../../../assets/images/employees/no-employee.svg" style="width: 125px">
                <h5 class="fw-bold">No Follows</h5>
                <p>Follow some business to show here</p>
            </div>
        </div>
    </div> -->

    <div class="card mb-0 border">
        <div class="card-header">
            <h3 class="card-title text-dark">Edit Profile</h3>
        </div>
        <div class="card-body">
            <!-- <div class="reviews-content"> -->
                <!-- <div class="reviews-header">
                    <div class="row" v-if="reviews_stats">
                        <div class="col-12" v-on:click.prevent="$router.push({name:'BusinessProfile',params: {slug:$route.params.slug,tab:'reviews'}})">
                            <h2 class="mb-4 d-flex" >
                                <span class="pt-2 me-3">{{reviews_stats.rating}}</span> 
                                <star-rating :rating="reviews_stats.rating" :star-size="30" :round-start-rating="false" :show-rating="false" :read-only="true"></star-rating> 
                                <span class="pt-sm-3 pt-2 ms-2 fs-6">({{reviews_stats.total_reviews}}) <span v-if="reviews_stats.total_reviews > 1">Reviews</span> <span v-else>Review</span></span>
                            </h2>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="progress-bar-wrapper">
                                <div class="fc">5</div>
                                <div class="sc">
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" :style="{width: getPercentage(reviews_stats.total_five,reviews_stats.total_reviews) + '%' }" :aria-valuenow="reviews_stats.total_five" aria-valuemin="0" :aria-valuemax="reviews_stats.total_reviews"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="progress-bar-wrapper">
                                <div class="fc">4</div>
                                <div class="sc">
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" :style="{width: getPercentage(reviews_stats.total_four,reviews_stats.total_reviews) + '%' }" :aria-valuenow="reviews_stats.total_four" aria-valuemin="0" :aria-valuemax="reviews_stats.total_reviews"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="progress-bar-wrapper">
                                <div class="fc">3</div>
                                <div class="sc">
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" :style="{width: getPercentage(reviews_stats.total_three,reviews_stats.total_reviews) + '%' }" :aria-valuenow="reviews_stats.total_three" aria-valuemin="0" :aria-valuemax="reviews_stats.total_reviews"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="progress-bar-wrapper">
                                <div class="fc">2</div>
                                <div class="sc">
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" :style="{width: getPercentage(reviews_stats.total_two,reviews_stats.total_reviews) + '%' }" :aria-valuenow="reviews_stats.total_two" aria-valuemin="0" :aria-valuemax="reviews_stats.total_reviews"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="progress-bar-wrapper">
                                <div class="fc">1</div>
                                <div class="sc">
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" :style="{width: getPercentage(reviews_stats.total_one,reviews_stats.total_reviews) + '%' }" :aria-valuenow="reviews_stats.total_one" aria-valuemin="0" :aria-valuemax="reviews_stats.total_reviews"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12" v-if="reviews_stats">
                            <div class="stars-wrapper">
                                <span class="me-3 fc">Quality of Service</span>
                                <div class="sc"><star-rating :rating="reviews_stats.avg_quality" :title="reviews_stats.avg_quality" :round-start-rating="false" :star-size="18" :show-rating="false" :read-only="true"></star-rating></div>
                            </div>
                            <div class="stars-wrapper">
                                <span class="me-3 fc">Skill</span>
                                <div class="sc"><star-rating :rating="reviews_stats.avg_skill" :title="reviews_stats.avg_skill" :round-start-rating="false" :star-size="18" :show-rating="false" :read-only="true"></star-rating></div>
                            </div>
                            <div class="stars-wrapper">
                                <span class="me-3 fc">Communication</span>
                                <div class="sc"><star-rating :rating="reviews_stats.avg_communication" :title="reviews_stats.avg_communication" :round-start-rating="false" :star-size="18" :show-rating="false" :read-only="true"></star-rating></div>
                            </div>
                            <div class="stars-wrapper">
                                <span class="me-3 fc">Adherence to Schedule</span>
                                <div class="sc"><star-rating :rating="reviews_stats.avg_timing" :title="reviews_stats.avg_timing" :round-start-rating="false" :star-size="18" :show-rating="false" :read-only="true"></star-rating></div>
                            </div>
                            <div class="stars-wrapper">
                                <span class="me-3 fc">Would recommend</span>
                                <div class="sc"><star-rating :rating="reviews_stats.avg_recommendation" :title="reviews_stats.avg_recommendation" :round-start-rating="false" :star-size="18" :show-rating="false" :read-only="true"></star-rating></div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="reviews-inner-body" v-if="reviews">
                    <div class="review-box" v-for="review in reviews" :key="review.id">
                        <div class="author">
                            <span class="author-image"><img src="@/assets/images/icons/avatr.png" /></span>
                            <span class="author-name">{{review.business.title}}</span>
                            <span class="post-date">May 27, 2021</span>
                        </div>

                        <div class="review-rating">
                            <star-rating :rating="review.overall_rating" :title="review.overall_rating" :round-start-rating="false" :star-size="18" :show-rating="false" :read-only="true"></star-rating>
                            <p class="mb-2">Service: Full Makeup</p>
                        </div>
                        <div class="review-description">
                            <p v-if="review.business_review.length > 155"><span>{{review.business_review.substring(0, 155)}}</span><span v-if="readMore">{{review.business_review.substring(155, 4000)}}</span> <a href="#!" @click="readMore =! readMore"><span v-if="readMore">See Less</span><span v-else>See More</span></a></p>
                            <p v-else>{{review.business_review}}</p>
                            <div class="review-help-btn pt-2">
                                <p class="mb-0 text-muted"><span id="helpfulCounter_{review.id}">15</span> people found this helpful</p>
                                <button id="helpful" class="btn help-button shadow-sm"  v-on:click="helpFulReview(review.id,review.business_id)" >Helpful</button>
                                <button class="btn report-button" v-on:click="reportReview(review.id,review.business_id)">Report</button>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </div> -->
        </div>
    </div>
</template>

<script>
    // import $ from 'jquery'
    import axios from 'axios'
    import { useToast } from "vue-toastification"
    import StarRating from 'vue-star-rating'

    export default {
        setup () {
            const toast = useToast()
            return { toast }
        },
        components: {
            StarRating
        },
        data(){
            return{
                reviews: [],
            }
        },
        created() {
            this.getreviews()
        },
        methods: {
            getPercentage(obtain,total){
                return (obtain/total)*100;
            },
            getreviews(){
                let thiss = this
                axios.get('reviews/user/'+thiss.$storage.getStorageSync('user').id).then(function (response) {
                    if (response.data.status == 'success') {
                        thiss.reviews = response.data.data
                        console.log(thiss.reviews)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        thiss.toast.error(error.response.data.message)
                        console.log(error.response.data.message)
                    }
                });
            },
            deleteReview(rev_id){
                let thiss = this
                this.$swal({
                    title: 'Are you sure?',
                    text: "You want to delete this!",
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete it!',
                    reverseButtons: true,
                    customClass: {
                        confirmButton: 'ond-btn-p',
                        cancelButton: 'ond-btn-w me-3'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.post('delete_review/',{rev_id: rev_id}).then(function (response) {
                            //console.log(response.data)
                            thiss.toast.success(response.data.message)
                            thiss.getreviews()
                        })
                        .catch(error => {
                            if (error.response) {
                                thiss.toast.error(error.response.data.message)
                            }
                        });
                    }
                })
            }
        }

    }
</script>

<style>
    .progress{
        height:10px
    }
    .progress-bar-wrapper,.stars-wrapper{
        width: 100%;
        display: flex;
        margin-bottom: 5px;
    }
    .progress-bar-wrapper .fc{
        width: 30px;
        float: left;
    }
    .progress-bar-wrapper .sc{
        width: calc(85% - 30px);
        float: left;
        padding-top: 6px;
    }
    .stars-wrapper .fc{
        width:60%;
    }
    .stars-wrapper .sc{
        width:40%;
        margin-top: -2px;
    }
</style>
<style scoped>
    .review-box{
        border: 1px solid #e2e2e2;
        padding: 15px;
        border-radius: 5px
    }
</style>