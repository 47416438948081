<template>
    <AppContainer>
        <Reviews/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/UserDashboardContainer.vue'
import Reviews from '@/components/userDashboard/reviews/Reviews.vue'
export default {
  components: {
    AppContainer,
    Reviews
  }
}
</script>